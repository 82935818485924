/* @tallwind base;
@tailwind components;
@tailwind utilities; */


@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: outfit;
    scroll-behavior: smooth;
}

body{
    min-height: 100vh;
}
a{
    text-decoration: none;
    color: inherit;
}

.app{
    width: 80%;
    margin: auto;
}

