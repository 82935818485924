/* src/pages/CardPage/CardPage.css */

.card-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh; /* Ensure full viewport height to avoid scrolling */
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1000px;
  width: 100%;
  background: #2c2c2c;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.card-image {
  flex: 1;
  max-width: 40%; /* Adjusted width */
  padding-right: 20px;
}

.card-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-details {
  flex: 1;
  max-width: 50%; /* Adjusted width */
  text-align: left;
  color: #f0f0f0;
}

.card-details h2 {
  font-size: 1.8em;
  color: #ffffff;
  margin: 10px 0;
}

.card-price {
  font-size: 1.5em;
  color: #d9534f;
  margin: 10px 0;
}

.card-availability {
  font-size: 0.9em;
  color: #9acd32;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.card-availability:before {
  content: '⚡';
  margin-right: 5px;
}

.add-to-cart-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 20px;
}

.add-to-cart-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.card-info {
  margin-top: 20px;
  display: grid;
  gap: 10px;
}

.card-info h3 {
  font-size: 1.2em;
  color: #ffffff;
  margin-bottom: 10px;
}

.card-info p {
  font-size: 0.9em;
  color: #dddddd;
  line-height: 1.4;
}

.card-info .product-info {
  font-size: 0.9em;
  color: #dddddd;
}

.product-info .info-label {
  color: #9acd32;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    padding: 15px;
  }

  .card-image {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }

  .card-details {
    max-width: 100%;
    text-align: center;
  }

  .card-details h2 {
    font-size: 1.8em;
  }

  .card-price {
    font-size: 1.5em;
  }

  .card-availability {
    font-size: 1em;
  }

  .add-to-cart-btn {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
  }

  .card-info h3 {
    font-size: 1.3em;
  }

  .card-info p {
    font-size: 1.1em;
  }
}

@media (max-width: 480px) {
  .card-details h2 {
    font-size: 2em;
  }

  .card-price {
    font-size: 2em;
    margin-right: -10px;
  }

  .card-availability {
    font-size: 1em;
  }

  .add-to-cart-btn {
    font-size: 1em;
  }

  .card-info h3 {
    font-size: 1.5em;
  }

  .card-info p {
    font-size: 1em;
  }
}
