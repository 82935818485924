.cards-display {
  margin-top: 30px;
  margin-left:-15px ;
  margin-right:-15px ;
}

.cards-display h2 {
  font-size: max(2vw, 24px);
  font-weight: 600;
}

.cards-display-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 30px;
  gap: 20px;
  row-gap: 50px;
}

/* Responsive design for small screens */
@media (max-width: 600px) {
  .cards-display-list {
    grid-template-columns: repeat(2, 0.5fr);
  }
}
