.login-popup {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  display: grid;
}
.login-popup-container {
  place-self: center;
  width: max(30vw, 330px);
  color: #808080;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px 30px;
  border-radius: 8px;
  font-size: 14px;
  animation: fadeIn 0.5s;
}

.login-popup-title {
 display: flex;
 justify-content: space-between;
 align-items: center;
 color:black
} 

.login-popup-inputs{
    display: flex;
    flex-direction: column;
    gap:20px
}


.login-popup-title img {
    width: 16px;
    cursor: pointer;
}

.login-popup-inputs input{
    outline: none;
    border: 1px solid #c9c9c9;
    padding: 10px;
    border-radius: 4px;
}
.login-popup-container button{
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: white;
    background-color: rgb(32, 46, 135);
    font-size: 15px;
    cursor: pointer;
}
.login-popup-condition{
    display: flex;
    align-items: start;
    gap: 8px;
    margin-top: -15px;
}
.login-popup-condition input{

    margin-top: 5px;
}
.error-message {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
}

.login-popup p span{
    color:rgb(205, 91, 91);
    font-weight: 500;
    cursor: pointer;
}
.login-popup-social {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icon {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
  filter: brightness(0.9);
}

.social-icon.google-icon {
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.social-icon.apple-icon {
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
