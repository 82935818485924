/* ExploreStore.css */

.explore-store {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #333; /* Slightly darker for better contrast */
    font-weight: 500;
    padding: 0 10px; /* Added padding for better spacing */
}

.explore-store-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 20px; /* Reduced gap for a cleaner look */
    margin: 20px 0;
    overflow-x: auto; /* 'auto' instead of 'scroll' for better UX */
    padding: 10px 0; /* Added padding for better spacing */
    scrollbar-width: none; /* For Firefox */
}

.explore-store-list::-webkit-scrollbar {
    display: none; /* For WebKit browsers */
}

.explore-store-list img {
    width: 30vw; /* Slightly smaller for better proportion */
    max-width: 200px; /* Max-width to ensure images aren't too large */
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions */
    border-radius: 12px; /* Slightly smaller border radius */
}

.explore-store-list img:hover {
    transform: scale(1.05); /* Zoom effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

.explore-store-list p {
    margin-top: 5px; /* Reduced margin for a tighter look */
    font-size: clamp(14px, 2vw, 18px); /* Responsive font size */
    cursor: pointer;
    color: #0073e6; /* Changed to a more modern shade of blue */
    transition: color 0.3s; /* Smooth color transition */
}

.explore-store-list p:hover {
    color: #005bb5; /* Darker shade on hover */
}

.explore-store-list hr {
    margin: 10px 0;
    height: 1px; /* Thinner line for a more modern look */
    background-color: #0073e6; /* Match with text color */
    border: none;
}

.explore-store-list-item .active {
    border: 4px solid #f54b5e; /* Updated to a more vibrant color */
    padding: 2px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for emphasis */
}

/* Media query for small screens */
/* @media (max-width: 750px) {
    .explore-store {
        margin-top: 0px; 
    }
} */
