.app-download {
    margin: auto auto;
    margin-top: 100px;
    font-size: max(3vw, 20px);
    text-align: center;
    font-weight: 500;
}

.app-download-platforms {
    display: flex;
    justify-content: center;
    gap: max(2vw, 10px);
    margin-top: 40px;
}

.app-download-platforms img {
    width: max(30vw, 120px);
    max-width: 180px;
    transition: 0.5s;
    cursor: pointer;
}

.app-download-platforms img:hover {
    transform: scale(1.05);
}

.app-download-platforms div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-download-platforms div p {
    margin-top: 8px; /* Adjust the spacing as needed */
    font-size: 14px; /* Adjust the font size as needed */
    color: #555; /* Adjust the color as needed */
}
