.shopping-cart-container {
  max-width: 1000px;
  margin: 10px auto;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #2d2f34; /* Dark background color */
  border-radius: 10px;
  color: #ffffff; /* White text for contrast */
}

.shopping-cart-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px; /* Larger header for emphasis */
  color: #ffffff; /* White text color */
}

.cart-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.shopping-cart-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  margin-right: 20px;
}

.shopping-cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #3a3b3e; /* Dark border for a clean look */
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #3a3b3e; /* Darker background for items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.item-details {
  display: flex;
  align-items: center;
}

.item-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.item-description {
  flex: 1;
}

.item-name {
  font-size: 20px;
  font-weight: 600; /* Slightly lighter font weight */
  color: #ffffff; /* White color for better contrast */
}

.item-price {
  color: #da5959; /* Red color for price */
  font-size: 22px;
  font-weight: 700; /* Bolder font weight for emphasis */
  margin-top: 10px;
}

.item-quantity-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.item-quantity-select {
  padding: 5px;
  border-radius: 5px;
  background-color: #3a3b3e;
  color: #ffffff;
  border: 1px solid #3a3b3e;
  font-size: 16px;
}

.item-quantity-select:focus {
  outline: none;
  border-color: #e16464; /* Red color for focus */
}

.remove-button {
  background-color: #d9534f; /* Red color for remove card button */
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.remove-button:hover {
  background-color: #c9302c; /* Darker red for remove card button hover */
  transform: scale(1.05);
}

.cart-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px; /* Increased padding for more space */
  background-color: #3a3b3e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-basis: 300px; /* Increased width for the summary section */
  margin-top: 20px; /* Added margin-top to separate from cart items */
}

.cart-total {
  font-size: 24px; /* Increased font size */
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
}

.checkout-btn {
  background-color: #137810; /* Matching checkout button color */
  border: none;
  color: white;
  padding: 20px 40px; /* Increased padding for a bigger button */
  cursor: pointer;
  border-radius: 8px;
  font-size: 20px; /* Increased font size */
  transition: background-color 0.3s, transform 0.2s;
  width: 100%; /* Full width */
  text-align: center; /* Centered text */
}

.checkout-btn:hover {
  background-color: #2ea626; /* Slightly darker green on hover */
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .cart-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .shopping-cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image {
    margin-bottom: 15px;
  }

  .item-quantity-actions {
    flex-direction: row;
  }

  .remove-button {
    width: auto;
    font-size: 18px;
    margin: 5px 0;
  }

  .checkout-btn {
    width: 100%;
  }

  .cart-summary {
    width: 100%;
    padding: 20px; /* Adjusted padding for smaller screens */
  }
}
