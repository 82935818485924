.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
 
}

.loading-logo {
  max-width: 200px; /* Adjust the size of your logo */
  max-height: 200px;
}
