.header {
  position: relative;
}

.header-img {
  height: 30vw;
  margin: 30px auto;
  background-size: contain;
  position: relative;
}

.header-img img {
  width: 100%;
  max-height: 400px;
}

/* Adjust the height for smaller screens */
@media (max-width: 1050px) {
  .header-img {
    height: 70vw;
  }
}

@media (max-width: 750px) {
  .header-img {
    display: flex;
    justify-content: center;
  }

  .header-img img {
    content: url('../../../public/header.webp');
    width: 100%; /* Further reducing the width */
    max-width: 2000px; /* Setting a max width to limit the size */
    height: auto; /* Ensure aspect ratio is maintained */
  }
}
