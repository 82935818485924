.footer{
    color: #d9d9d9;
    background-color: #323232;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 6vw;
    padding-top: 40px;
    margin-top: 150px;
}
.footer-content{
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr ;
    gap: 80px;
}

.footer-content-left , .footer-content-center ,.footer-content-right{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}
.footer-content-left span{
    color: rgb(170, 42, 42);
    font-size:x-large;
}
.footer-content-left li, .footer-content-center li,.footer-content-right li{
    list-style: none;
    margin-bottom: 10px;
    cursor: pointer;
}


 .footer-content-center ul,.footer-content-right ul{
    padding-left: 0px;
}


.footer-content-center h2,.footer-content-right h2{
    color: white;
}

.footer-social-icons img{

    width: 40px;
    margin-right: 15px;
}

.footer hr{
    width: 100%;
    height: 2px;
    margin: 20px 0;
    background-color: rgb(253, 253, 253);
    border: none;
}

@media (max-width:750px) {
    .footer-content{
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    .footer-copyright{
        text-align: center;
    }
}