.card-item {
  width: 100%;
  margin: auto;
  border-radius: 15px;
  box-shadow: 30px 30px 30px 30px #00000015;
  transition: 0.3s;
  animation: fadeIn 1s;
}

.card-item-link {
  text-decoration: none;
  color: inherit;
}


.card-item-img {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}
.card-item-info {
  padding: 12px;
}

.card-item-name-shopping img {
  width: 60px;
}


.card-item-name-shopping {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-item-price{
  color: rgb(162, 50, 54);
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0px;
}

.card-item-name{
  font-size: 15px;
  font-weight: 400;
  color:rgb(0, 0, 0);
}

.card-item:hover{
  transform: scale(1.05);
  cursor: pointer;
}