.verification-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  max-width: 100%;
  z-index: 1000;
}

.verification-popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verification-popup-title {
  text-align: center;
  margin-bottom: 20px;
}

.verification-popup-title h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.verification-description {
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #555;
}

.verification-popup-input input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.verification-button {
  width: 100%;
  padding: 10px;
  background-color: #003087;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verification-button:hover {
  background-color: #002766;
}
