/* Navbar.css */

.navbar-header {
  position: fixed;
  top: 0;
  width: 80%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
  height: 90px;
  border-radius: 14px;
  background-color: #fff;
  z-index: 1000; 
}

.navbar-header .logo {
  width: 100px;
}

.navbar-header ul {
  margin-top: -3px;
}

.navbar-header button {
  background: transparent;
  font-size: 16px;
  color: #0a2787;
  border: 1px solid rgb(11, 35, 168);
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}

.navbar-header button:hover {
  background-color: rgb(53, 66, 143);
  color: aliceblue;
}

.navbar-menu-header {
  display: flex;
  list-style: none;
  gap: 20px;
  color: #040405;
  font-size: 18px;
  align-items: center;
  margin-top: 10px;
  margin-left: 50px;
  text-decoration: none;
}

.navbar-menu-header a {
  text-decoration: none;
  color: #0c0d10;
}

.navbar-right-header {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-right-header img {
  width: 25px;
}

.navbar-header .active {
  padding-bottom: 2px;
  border-bottom: 2px solid #000000;
}

.navbar-header li {
  cursor: pointer;
}

.navbar-search-icon {
  position: relative;
}

.navbar-search-icon .dot {
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  background-color: red;
  border-radius: 5px;
  top: -8px;
  right: -8px;
}

.navbar-profile {
  position: relative;
}

.navbar-profile-dropdown {
  position: absolute;
  display: none;
  right: 0;
  z-index: 1;
}

.navbar-profile:hover .navbar-profile-dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #394876;
  padding: 12px 25px;
  border-radius: 4px;
  border: 1px solid rgb(10, 39, 101);
  outline: 2px solid white;
  list-style: none;
}

.navbar-profile-dropdown li {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #d9a3ab;
}

.navbar-profile-dropdown img {
  width: 20px;
}

.navbar-profile-dropdown li:hover {
  color: #bd7377;
}

@media (max-width: 1050px) {
  .navbar-header .logo {
    width: 125px;
  }
  .navbar-menu-header {
    gap: 20px;
    font-size: 17px;
  }

  .navbar-right-header {
    gap: 30px;
  }

  .navbar-right-header img {
    width: 22px;
  }

  .navbar-right-header button {
    padding: 8px 25px;
  }
}

@media (max-width: 900px) {
  .navbar-header .logo {
    width: 120px;
  }
  .navbar-menu-header {
    gap: 15px;
    font-size: 16px;
  }

  .navbar-right-header {
    gap: 20px;
  }

  .navbar-right-header img {
    width: 20px;
  }

  .navbar-right-header button {
    padding: 7px 20px;
    font-size: 15px;
  }
}

@media (max-width: 750px) {
  .navbar-menu-header {
    display: none;
  }

  .logo {
    width: 10px;
  }
}

/* Ensure the content below the navbar starts after the navbar */
.main-content {
  margin-top: 110px; /* Adjust this value based on your navbar height */
}
